import React, { useState, useRef, ChangeEvent, MouseEvent } from "react";

export default function Mobile(props: HeaderProps) {
  const [searchQuery, setSearchQuery] = useState<string>(
    props.searchQuery || ""
  );
  const [showNav, setShowNav] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<NavItem | false>(
    props.currentRoot || false
  );
  const searchRef = useRef<HTMLInputElement>(null);

  const changeSearchQuery = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setSearchQuery(evt.target.value);
  };

  const isExpanded = (p: NavItem): boolean => {
    return expanded !== false && p.id === expanded.id;
  };

  const expand = (p: NavItem, evt: MouseEvent<HTMLElement>) => {
    const target = evt.target as HTMLElement;
    if (target.tagName !== "A") {
      if (expanded && expanded.id === p.id) {
        setExpanded(false);
      } else {
        setExpanded(p);
      }
    }
  };

  const toggleNav = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    setShowNav(!showNav);
  };

  const navItems = props.navItems;

  return (
    <header>
      <div className="inner">
        <div className="main">
          <a href="/" className="logo">
            Mental Helse
          </a>
          <div className="buttons">
            {props.becomeMember && (
              <a
                className="become-member small button"
                href={props.becomeMember.url}>
                Bli medlem!
              </a>
            )}
            {props.supportUs && (
              <a
                className="support-us small button light"
                href={props.supportUs.url}>
                Støtt oss
              </a>
            )}
            <button
              className={"nav-toggle " + (showNav ? "close" : "")}
              onClick={toggleNav}>
              Vis meny
            </button>
          </div>
          {showNav && (
            <nav>
              <ul>
                {navItems.map((p) => (
                  <li
                    key={p.id}
                    className={isExpanded(p) ? "expanded" : ""}
                    onClick={(evt) => expand(p, evt)}>
                    {p.pages.length > 0 && (
                      <button
                        className="expand-button"
                        onClick={(evt) => expand(p, evt)}>
                        Vis
                      </button>
                    )}
                    <a
                      className={p.currentAncestor ? "current" : ""}
                      onClick={(evt) => evt.stopPropagation()}
                      href={p.url}>
                      {p.name}
                    </a>
                    {expanded && isExpanded(p) && (
                      <nav className="subnav">
                        <ul>
                          {expanded.pages.map((p) => (
                            <li key={p.id}>
                              <a
                                className={p.currentAncestor ? "current" : ""}
                                href={p.url}>
                                {p.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </nav>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
      </div>
      {showNav && (
        <div className="search-bar">
          <div className="inner">
            <form action="/search" method="get">
              <label htmlFor="search-query">Søk etter</label>
              <div className="search-field">
                <input
                  id="search-query"
                  name="q"
                  type="text"
                  ref={searchRef}
                  value={searchQuery}
                  onChange={changeSearchQuery}
                />
                <button type="submit">Søk</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </header>
  );
}

// Entry point for the build script in your package.json

// Window animation
import WindowAnimation from "./lib/WindowAnimation";
WindowAnimation.start();

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;

// Tracking
document.addEventListener("DOMContentLoaded", () => {
  document
    .querySelectorAll('a[href*="tel:"]')
    .forEach((link: HTMLAnchorElement) => {
      link.addEventListener("click", () => {
        if (typeof window.fbq !== "undefined") {
          window.fbq("track", "Contact");
        }
      });
    });
});

import React, { useEffect, useState } from "react";
import WindowAnimation from "../lib/WindowAnimation";
import Desktop from "./Header/Desktop";
import Mobile from "./Header/Mobile";

export default function Header(props: HeaderProps) {
  const [mobile, setMobile] = useState(false);

  const checkWindowSize = (width) => {
    setMobile(width <= 990);
  };

  useEffect(() => {
    const handle = WindowAnimation.register({ resize: checkWindowSize });
    return () => {
      WindowAnimation.clearHandler(handle);
    };
  }, []);

  if (mobile) {
    return <Mobile {...props} />;
  } else {
    return <Desktop {...props} />;
  }
}

import React, { useState, useRef, useEffect, ChangeEvent } from "react";

export default function Desktop(props: HeaderProps) {
  const [searchQuery, setSearchQuery] = useState<string>(
    props.searchQuery || ""
  );
  const [showSearch, setShowSearch] = useState<boolean>(
    props.showSearch || !!props.searchQuery
  );
  const searchRef = useRef<HTMLInputElement>(null);
  const [focusRef, setFocusRef] =
    useState<React.RefObject<HTMLInputElement> | null>(null);

  const changeSearchQuery = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setSearchQuery(evt.target.value);
  };

  const toggleSearch = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (showSearch) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
      setFocusRef(searchRef);
    }
  };

  useEffect(() => {
    if (focusRef && focusRef.current) {
      focusRef.current.focus();
      setFocusRef(null);
    }
  }, [focusRef]);

  const { navItems, currentRoot, becomeMember, supportUs } = props;

  return (
    <header className="desktop">
      <div className="inner">
        <div className="main">
          <a href="/" className="logo">
            Mental Helse
          </a>
          <nav>
            <ul>
              {navItems.map((p: NavItem) => (
                <li key={p.id}>
                  <a
                    className={p.currentAncestor ? "current" : ""}
                    href={p.url}>
                    {p.name}
                  </a>
                </li>
              ))}
              {(becomeMember || supportUs) && (
                <li>
                  {becomeMember && (
                    <a
                      className="become-member small button"
                      href={becomeMember.url}>
                      {becomeMember.name}
                    </a>
                  )}
                  {supportUs && (
                    <a
                      className="support-us small button light"
                      href={supportUs.url}>
                      {supportUs.name}
                    </a>
                  )}
                </li>
              )}
              <li>
                <a href="/search" onClick={toggleSearch}>
                  Søk
                </a>
              </li>
            </ul>
          </nav>
        </div>
        {currentRoot && currentRoot.pages.length > 0 && (
          <nav className="subnav">
            <ul>
              <li>
                <a
                  className={currentRoot.current ? "current" : ""}
                  href={currentRoot.url}>
                  {currentRoot.name}
                </a>
              </li>
              {currentRoot.pages.map((p: NavItem) => (
                <li key={p.id}>
                  <a
                    className={p.currentAncestor ? "current" : ""}
                    href={p.url}>
                    {p.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
      {showSearch && (
        <div className="search-bar">
          <div className="inner">
            <form action="/search" method="get">
              <label htmlFor="search-query">Søk etter</label>
              <div className="search-field">
                <input
                  id="search-query"
                  name="q"
                  type="text"
                  ref={searchRef}
                  value={searchQuery}
                  onChange={changeSearchQuery}
                />
                <button type="submit">Søk</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </header>
  );
}

import React, { useState } from "react";

interface Option {
  name: string;
  url: string;
}

interface Props {
  current: string;
  options: Option[];
}

export default function LinkSelect(props: Props) {
  const { current, options } = props;

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setExpanded(!expanded);
  };

  const currentLabel = options.filter((o) => o.url === current)[0];

  const linkClass = (option: Option) => {
    if (option.url === current) {
      return "current";
    }
  };

  return (
    <div className="link-select">
      <a href={props.current} className="label" onClick={toggleExpanded}>
        {currentLabel.name}
      </a>
      {expanded && (
        <ul>
          {options.map((o) => (
            <li key={"option-" + o.name}>
              <a href={o.url} className={linkClass(o)}>
                {o.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
